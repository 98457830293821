import DynamicComponent from "../DynamicComponent";
import { storyblokEditable } from "@storyblok/react";
import { Box } from "@chakra-ui/react";

interface LocationLandingPageProps {
  blok: any;
  globalState: GlobalState;
  setGlobalState: React.Dispatch<React.SetStateAction<GlobalState>>;
  locationState: RootsLocation;
  ppm365: {location: RootsLocation, updates: Ppm365Update[]};
}

const LocationLandingPage = ({ blok, globalState, setGlobalState, locationState, ppm365 }: LocationLandingPageProps):JSX.Element => {
  if (locationState === null) return null;
  
  return (
    <Box {...storyblokEditable(blok)}>
      {blok.blocks
        ? blok.blocks.map((blok) => (
          <DynamicComponent blok={blok} key={blok._uid} globalState={globalState} setGlobalState={setGlobalState} locationState={locationState} ppm365={ppm365} />
          ))
        : null}
    </Box>
  )
};

export default LocationLandingPage;
