import { DateTime } from "luxon";

export type ServerDateTypes = "utc" | "central" | "local"

class DateUtils {
    static topOfHour(d: Date) {
        return new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), 0, 0, 0);
    }

    static getAge(dateString: string | number | Date) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var month = today.getMonth() - birthDate.getMonth();
        if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    static parseServerDate(isoDate: string, type: ServerDateTypes) {

        switch (type) {
            case "utc":
                return DateTime.fromISO(isoDate)
            case "central":
                return DateTime.fromISO(isoDate, { setZone: true }).setZone("America/Chicago", { keepLocalTime: true })
            case "local":
                return DateTime.fromISO(isoDate, { setZone: true }).setZone("local", { keepLocalTime: true })
        }

    }
}

export default DateUtils;