import DynamicComponent from "../DynamicComponent";
import { Grid as ChakraGrid, GridItem } from '@chakra-ui/react'
import { storyblokEditable } from "@storyblok/react";

const TwoColumns = ({ blok, globalState, setGlobalState, locationState, ppm365 }) => {
  const leftColumns = blok.columnWidths === 'equal' || blok.columnWidths === 'leftTwoThirds' ? 2 : 1;
  const rightColumns = blok.columnWidths === 'equal' || blok.columnWidths === 'rightTwoThirds' ? 2 : 1;
  const totalColumns = leftColumns + rightColumns;
  
  const verticalAlignment = blok.verticalAlign === 'top' ? 'flex-start' : blok.verticalAlign === 'bottom' ? 'flex-end' : 'center';
  const horizontalAlignment = blok.horizontalAlign === 'left' ? 'flex-start' : blok.horizontalAlign === 'right' ? 'flex-end' : 'center';

  return (
    <ChakraGrid
      templateColumns={{base: '1fr', lg: `repeat(${totalColumns}, 1fr)`}}
      w="full"
      gap={blok.columnGap}
      {...storyblokEditable(blok)}
    >
      <GridItem 
        display="flex"
        flexDirection="column"
        justifyContent={verticalAlignment}
        alignItems={horizontalAlignment}
        overflow="hidden"
        colSpan={{ base: 1, lg: leftColumns }} 
        bgColor={`${blok.leftBackgroundColor}.600`} 
        p={blok.leftPadding} 
        borderRadius={blok.leftBorderRadius} 
        maxWidth={{base: '100%', lg: Math.round(blok.leftColumns / totalColumns * 100) + '%'}}
      >
        {blok.leftContent.map((nestedBlok, index) => (
          <DynamicComponent key={index} blok={nestedBlok} globalState={globalState} setGlobalState={setGlobalState} locationState={locationState} ppm365={ppm365} />
        ))}
      </GridItem>
      
      <GridItem 
        display="flex"
        flexDirection="column"
        justifyContent={verticalAlignment}
        alignItems={horizontalAlignment}
        overflow="hidden"
        colSpan={{ base: 1, lg: rightColumns }} 
        bgColor={`${blok.rightBackgroundColor}.600`} 
        p={blok.rightPadding} 
        borderRadius={blok.rightBorderRadius} 
        maxWidth={{ base: '100%', lg: Math.round(blok.rightColumns / totalColumns * 100) + '%' }}
      >
        {blok.rightContent.map((nestedBlok, index) => (
          <DynamicComponent key={index} blok={nestedBlok} globalState={globalState} setGlobalState={setGlobalState} locationState={locationState} ppm365={ppm365} />
        ))}
      </GridItem>
    </ChakraGrid>
  )
};

export default TwoColumns;
