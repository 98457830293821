import { storyblokEditable } from "@storyblok/react"
import { useContext } from "react"
import { PartnerProfileContext } from "../../contexts/ppm365"
import Box from "../../layout/Box"

const PartnerFundedContainer = ({ blok, globalState, setGlobalState, locationState , ppm365}): JSX.Element => {

    const profile = useContext(PartnerProfileContext)

    return (
        profile.funded && 
        <Box blok={blok} globalState={globalState} setGlobalState={setGlobalState} locationState={locationState} ppm365={ppm365}></Box>
    )

}

export default PartnerFundedContainer