import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Text, VisuallyHidden } from "@chakra-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { checkSignIn, PelicanAccountApi, SignUpForm } from "../../../../utils/pelicanAccount"
import { DynamicComponentProps } from "../../../DynamicComponent"
import * as CSS from 'csstype';

interface SignUpFormProps extends DynamicComponentProps {
    created?: () => void
    exists?: () => void
    buttonText?: string,
    inputLabelFontSize?: CSS.Property.FontSize
}

const inputStyle = {
    variant: "outline",
    size: 'md',
    layerStyle: 'lightForm',
    border: "1px solid",
    borderColor: 'brand.orange.600',
}

const SignUpForm = ({ globalState, setGlobalState, created = () => { }, exists, buttonText = "Create Account", inputLabelFontSize = "medium" }: SignUpFormProps): JSX.Element => {

    const form = useForm<SignUpForm>({})
    const [errorMessage, setErrorMessage] = useState('')
    const [submitting, setSubmitting] = useState(false)

    const onSubmit = async (data: SignUpForm) => {
        try {
            setSubmitting(true)
            const response = await PelicanAccountApi.createAccount(data)

            if (response.result === "success") {

                const signInData = await checkSignIn()
                if (signInData?.userName) {
                    setGlobalState({
                        ...globalState,
                        user: {
                            name: signInData.userName,
                            email: signInData.email,
                            id: signInData.id,
                            firstName: signInData.firstname,
                            lastName: signInData.lastname,
                            address1: signInData.address1,
                            address2: signInData.address2,
                            city: signInData.city,
                            state: signInData.state,
                            zipcode: signInData.zipcode.toString()

                        }
                    })
                    created()
                }
                setSubmitting(false)
            }
            else if (response.result === "account_exists") {

                setErrorMessage("This account already exists.  Please sign in, instead.")
                setSubmitting(false)
            }

        }
        catch (error) {
            form.reset()
            setSubmitting(false)
            setErrorMessage('Looks like something went wrong.  Wait a few minutes and try again')
            console.error(error)
        }

    }




    return (
     
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <Flex
                direction="column"
                gap="5"
            >
                <FormControl
                    isInvalid={!!form.formState.errors.email}
                    isDisabled={submitting}
                    
                >
                    <VisuallyHidden><FormLabel fontSize={inputLabelFontSize}>Email Address</FormLabel></VisuallyHidden>
                    <Input
                        type={"email"}
                        placeholder={"Email Address"}
                        autoComplete={"username"}
                        {...form.register("email", { required: "Please enter your email address" })}
                        {...inputStyle}
                    ></Input>
                    <FormErrorMessage>{form.formState.errors?.email?.message}</FormErrorMessage>
                </FormControl>
                <FormControl
                    isInvalid={!!form.formState.errors.password}
                    isDisabled={submitting}
                  
                >
                    <VisuallyHidden>
                        <FormLabel fontSize={inputLabelFontSize}>Password</FormLabel>
                    </VisuallyHidden>
                    <Input
                        type={"password"}
                        placeholder={"Password"}
                        {...form.register("password", { required: "Please enter a password", minLength: {message: "Please enter at least 10 characters", value: 10} })}
                        {...inputStyle}
                    ></Input>
                    <FormErrorMessage>{form.formState.errors?.password?.message}</FormErrorMessage>
                </FormControl>
                <FormControl
                    isInvalid={!!form.formState.errors.firstName}
                    isDisabled={submitting}
                   
                >
                    <VisuallyHidden>
                        <FormLabel fontSize={inputLabelFontSize}>First Name</FormLabel>
                    </VisuallyHidden>
                    <Input
                        type={"text"}
                        placeholder={"First Name"}
                        {...form.register("firstName", { required: "Please enter your first name" })
                        }
                        autoComplete={"given-name"}
                        {...inputStyle}
                    ></Input>
                    <FormErrorMessage>{form.formState.errors?.firstName?.message}</FormErrorMessage>
                </FormControl>
                <FormControl
                    isInvalid={!!form.formState.errors.lastName}
                    isDisabled={submitting}
                   
                    mb={"1em"}
                >
                    <VisuallyHidden>
                        <FormLabel fontSize={inputLabelFontSize}>Last Name</FormLabel>
                    </VisuallyHidden>
                    <Input
                        type={"text"}
                        placeholder={"Last Name"}
                        {...form.register("lastName", { required: "Please enter your last name" })}
                        {...inputStyle}
                        autoComplete={"family-name"}
                    ></Input>
                    <FormErrorMessage>{form.formState.errors?.lastName?.message}</FormErrorMessage>
                </FormControl>
                {
                    errorMessage &&
                    <Text mb="1em" lineHeight={1} textAlign='center' color="red.500">{errorMessage}</Text>
                }
                <Button
                    type="submit"
                    colorScheme={"brand.green"}
                    mb={".5em"}
                    disabled={submitting}
                    leftIcon={submitting ? <FontAwesomeIcon icon={["fas", "circle-notch"]} className="fa-spin"></FontAwesomeIcon> : undefined}
                >
                    {buttonText}
                </Button>
            </Flex>
        </form>
    )
}

export default SignUpForm