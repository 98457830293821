import DynamicComponent from "../../DynamicComponent";
import { storyblokEditable } from "@storyblok/react";
import { ParallaxProvider, Parallax, ParallaxBanner } from 'react-scroll-parallax';
import { Container, Flex, Heading, Text } from "@chakra-ui/react";

const ParallaxHero = ({ blok, globalState, setGlobalState, locationState }) => {
  return (
    <ParallaxProvider {...storyblokEditable(blok)}>
      <ParallaxBanner
        layers={[
          { image: blok.backgroundImage.filename, speed: -20 },
        ]}
        style={{aspectRatio: '2/1'}}
      >
        <Container maxW="container.xl" position="absolute" height="100%">
          {blok.content.map((nestedBlok, index) => (
            <DynamicComponent blok={nestedBlok} key={index} globalState={globalState} setGlobalState={setGlobalState} locationState={locationState} />
          ))}
          {/* <Flex alignItems="center" justifyContent="center" height="100%" position="absolute" width="100%">
            <Text as='h1' color="white">Test</Text>
          </Flex> */}
        </Container>
      </ParallaxBanner>
    </ParallaxProvider>
  );
}

export default ParallaxHero;
