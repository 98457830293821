import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Center,
  Box,
  Text,
  ModalFooter,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, SetStateAction, useEffect } from "react";
import DynamicComponent from "../DynamicComponent";

interface Props {
  blok: any;
  globalState: GlobalState;
  setGlobalState: Dispatch<SetStateAction<GlobalState>>;
  locationState: RootsLocation;
  ppm365: { location: RootsLocation, updates: Ppm365Update[] };
}

const LocationTravelAdvisory = (props:Props):JSX.Element => {
  const advisoryModal = useDisclosure();

  useEffect(() => {
    advisoryModal.onOpen();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal onClose={advisoryModal.onClose} isOpen={advisoryModal.isOpen} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Center w="100%" flexDirection="column">
            <Box
              color={'brand.green.600'}
              my="4"
            >
              <FontAwesomeIcon
                icon={'exclamation-circle'}
                size={'3x' as SizeProp}
              />
            </Box>
            <Text as="h2" fontSize="3xl" textAlign={'center'}>
              Travel Advisory - {props.locationState.name}
            </Text>
          </Center>
        </ModalHeader>
        <ModalBody>
          {
            props.blok.content.map((nestedBlok, index) => (
              <DynamicComponent
                key={index}
                blok={nestedBlok}
                globalState={props.globalState}
                setGlobalState={props.setGlobalState}
                locationState={props.locationState}
                ppm365={props.ppm365}
              />
            ))
          }
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Button onClick={advisoryModal.onClose} colorScheme="brand.green">
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default LocationTravelAdvisory;