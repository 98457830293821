import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { storyblokEditable } from '@storyblok/react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import DynamicComponent, { DynamicComponentProps } from '../DynamicComponent';

interface ButtonModalProps extends DynamicComponentProps<ButtonModalBlok> {
 

}

const ButtonModal = ({blok, globalState, setGlobalState, locationState}: ButtonModalProps) => {
  const router = useRouter();
  const [autoOpen, setAutoOpen] = useState<boolean>(router.query[blok.autoOpenParameter] === '');

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setAutoOpen(router.query[blok.autoOpenParameter] === '');
  }, [blok.autoOpenParameter, router.query]);

  const handleOnClose = () => {
    setAutoOpen(false);
    onClose();
  }

  return (
    <>
      <Button 
        {...storyblokEditable(blok)}
        onClick={onOpen}
        size={blok.button.size}
        colorScheme={blok.button.colorScheme}
        variant={blok.button.variant}
        fontWeight={blok.button.fontWeight}
        lineHeight={blok.button.lineHeight ?? 0}
        width={blok.button.width}
        leftIcon={blok.leftIcon && blok.leftIcon.icon !== '' ? <FontAwesomeIcon icon={[blok.leftIcon.type, blok.leftIcon.icon]} /> : undefined}
        rightIcon={blok.rightIcon && blok.rightIcon.icon !== '' ? <FontAwesomeIcon icon={[blok.rightIcon.type, blok.rightIcon.icon]} /> : undefined}
        height={blok.button.height}
        whiteSpace={blok.button.whiteSpace}
      >
        {blok.button.buttonText}
      </Button>

      <Modal 
        isOpen={isOpen || autoOpen}
        onClose={handleOnClose}
        size={blok.modalSize}
        isCentered={blok.centerVertically}
        closeOnOverlayClick={blok.closeOnOverlayClick ?? true}      
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textColor={'brand.darkgray.600'}>
            {blok.modalTitle}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {
              (isOpen || autoOpen) && blok.modalContent?.map((nestedBlok, index) => (
                <DynamicComponent blok={nestedBlok} key={index} globalState={globalState} setGlobalState={setGlobalState} locationState={locationState} />
              ))
            }
          </ModalBody>

          {
            blok.showFooter && 
            <ModalFooter>
              <Button 
                colorScheme='brand.green' 
                size={'sm'} 
                mr={3} 
                onClick={onClose}
              >
                Close
              </Button>
            </ModalFooter>
          }
        </ModalContent>
      </Modal>
    </>
  )
}

export default ButtonModal;